<template>
  <div
    style="
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    "
  >
    <VideoChat
      id="videochat"
      ref="videochat"
      style="visibility: hidden"
    ></VideoChat>
    <PageTitle
      title="我的咨询"
      fixed
      :backTo="
        address1
          ? '/visitmx/' +
            address1 +
            '/' +
            address2 +
            '/' +
            address3 +
            '/' +
            address4 +
            '/' +
            address5
          : '/message'
      "
    >
    </PageTitle>
    <van-notice-bar
      mode="closeable"
      :text="tips"
      v-if="showTip"
      color="#1989fa"
      background="#ecf9ff"
    />
    <van-badge style="float: right" fixed top="0" v-if="unreadnum != 0">
      <div
        class="child"
        style="text-align: center; padding-top: 5px; margin-top: 20px"
        @click="GetHis(remainNum)"
      >
        <p
          style="padding-top: 5px; margin-top: -6px; padding-left: 5px"
          class="Unread"
        >
          {{ (unreadnum >= 99 ? "99+" : unreadnum) + "条历史消息" }}
        </p>
      </div>
    </van-badge>
    <div :style="height" style="clear: both; overflow-y: scroll" id="dhk">
      <van-loading
        type="spinner"
        bottom="0"
        top="0"
        left="0"
        right="0"
        :style="display"
        style="text-align: center"
      />
      <van-cell v-for="(msgtext, index) in receiveMsgText" :key="index">
        <div style="clear: both">
          <p
            style="color: #dfdfdf; left: 0; right: 0; text-align: center"
            v-if="PD(index) != '0'"
          >
            {{ PD(index) }}
          </p>
          <p v-else></p>
        </div>
        <a v-if="msgtext.msgtype == 4">
          <div
            style="float: left; padding-left: 5px; height: 150px"
            v-if="
              JSON.parse(msgtext.msg).tsy != '支付成功' &&
              JSON.parse(msgtext.msg).isSuccess != '1'
            "
          >
            <div class="list-left" :style="{ width: zfwidth }">
              <p style="padding: 10px 10px 0 10px; font-weight: 800; margin: 0">
                支付提醒：
              </p>
              <p style="padding: 5px 10px 0 10px; font-weight: 500; margin: 0">
                {{ JSON.parse(msgtext.msg).tsy }}
              </p>
              <!--							<p style="text-align: center;margin: 0" v-if="(msgtext.isshow)">-->
              <!--								<van-button type="primary" style="height: 25px;margin-bottom: 10px;margin-left: 20px"-->
              <!--									v-if="JSON.parse(msgtext.msg).tsy!='支付成功'&&JSON.parse(msgtext.msg).isSuccess!='1'"-->
              <!--									@click="Accounts()"-->
              <!--									:loading="settlementLoading">支付-->
              <!--								</van-button>-->
              <!--							</p>-->
            </div>
          </div>
          <div style="float: left; padding-left: 5px; height: 120px" v-else>
            <div class="list-left" :style="{ width: zfwidth }">
              <p style="padding: 10px 10px 0 10px; font-weight: 800; margin: 0">
                支付提醒：
              </p>
              <p style="padding: 5px 10px 0 10px; font-weight: 500; margin: 0">
                {{ JSON.parse(msgtext.msg).tsy }}
              </p>
            </div>
          </div>
        </a>
        <a v-if="msgtext.msgtype == 3 && msgtext.sendid != sendMsg.senderId">
          <img
            :src="Image"
            style="
              float: left;
              margin-top: 15px;
              width: 44px;
              height: 44px;
              border-radius: 50%;
            "
          />
          <div style="float: left">
            <label style="color: #000; font-size: 12px; margin: 0 5px">{{
              msgtext.senderName
            }}</label>
            <br />
            <div
              :class="{ animation: currentPlayVideo == 'audio' + index }"
              :style="{
                width: JSON.parse(msgtext.msg).time + 100 + 'px',
                height: '40px',
              }"
              @click="playAudio(msgtext, index)"
            >
              <div class="wifi-warp">
                <div>
                  <span
                    style="
                      font-size: 14px;
                      line-height: 35px;
                      white-space: nowrap;
                      margin-left: 50px;
                    "
                  >
                    {{
                      JSON.parse(msgtext.msg).time == 0
                        ? 1
                        : JSON.parse(msgtext.msg).time
                    }}s
                  </span>
                </div>
                <div>
                  <img
                    v-if="currentPlayVideo != 'audio' + index"
                    :src="'https://cloudhospitalwebpro.knjs.net/static/VoiceMessage3.png'"
                    style="
                      height: 20px;
                      width: 20px;
                      margin-top: 7px;
                      margin-right: 7px;
                      transform: rotate(180deg);
                    "
                  />
                  <img
                    v-if="currentPlayVideo == 'audio' + index"
                    :src="'https://cloudhospitalwebpro.knjs.net/static/VoiceMessage4.png'"
                    style="
                      height: 15px;
                      width: 15px;
                      margin-top: 11px;
                      margin-right: 18px;
                    "
                  />
                </div>
              </div>
            </div>
            <!--            <audio controls height="80" width="20" style="max-width: 250px" ref="audio">-->
            <!--              <source :src=JSON.parse(msgtext.msg).url type="audio/mpeg">-->
            <!--              <source :src=JSON.parse(msgtext.msg).url type="audio/ogg">-->
            <!--              <embed :src=JSON.parse(msgtext.msg).url height="50" width="20">-->
            <!--            </audio>-->
          </div>
        </a>

        <a v-if="msgtext.msgtype == 2 && msgtext.sendid != sendMsg.senderId">
          <img
            :src="Image"
            style="
              float: left;
              margin-top: 15px;
              width: 44px;
              height: 44px;
              border-radius: 50%;
            "
          />
          <div style="float: left">
            <label style="color: #000; font-size: 12px; margin: 0 5px">{{
              msgtext.senderName
            }}</label>
            <br />
            <img
              :src="msgtext.msg"
              style="max-width: 200px; max-height: 200px"
              :id="index"
              :key="index2"
              @click="getImg(msgtext.msg)"
            />
          </div>
        </a>
        <a v-if="msgtext.msgtype == 1 && msgtext.sendid != sendMsg.senderId">
          <img
            :src="Image"
            style="
              float: left;
              margin-top: 10px;
              width: 44px;
              height: 44px;
              border-radius: 50%;
            "
          />
          <div style="float: left">
            <label style="color: #000; font-size: 12px; margin: 0 5px">{{
              msgtext.senderName
            }}</label>
            <br />
            <p class="doctorCommunicationInputs">
              {{ msgtext.msg }}
            </p>
          </div>
        </a>
        <div
          v-if="msgtext.sendid == sendMsg.senderId && msgtext.msgtype != 17"
          class="chat-right"
        >
          <div>
            <label>{{ msgtext.senderName }}</label>
            <div>
              <div
                v-if="!msgtext.isSendSuccess"
                class="resend-icon"
                :id="iconPrexName + index"
                @click="ReSend(msgtext, index)"
              >
                <van-icon name="warning" color="red" />
              </div>
              <div>
                <p
                  v-if="msgtext.msgtype == 1"
                  class="customerCommunicationInputs"
                  style="text-align: left"
                >
                  {{ msgtext.msg }}
                </p>
                <img
                  v-if="msgtext.msgtype == 2"
                  :src="msgtext.msg"
                  style="max-width: 200px; margin: 0 5px; max-height: 200px"
                  :id="index"
                  @click="getImg(msgtext.msg)"
                  :key="index2"
                />
                <!--                <audio v-if="msgtext.msgtype==3" controls height="80" width="20" style="max-width: 250px" :src="JSON.parse(msgtext.msg).url">-->
                <!--                  <source :src=msgtext.msg type="audio/mpeg">-->
                <!--                  <source :src=msgtext.msg type="audio/ogg">-->
                <!--                  <embed :src=msgtext.msg height="50" width="20">-->
                <!--                </audio>-->
                <div
                  v-if="msgtext.msgtype == 3"
                  :class="{ animation: currentPlayVideo == 'audio' + index }"
                  :style="{
                    width: JSON.parse(msgtext.msg).time + 100 + 'px',
                    height: '40px',
                  }"
                  @click="playAudio(msgtext, index)"
                >
                  <div class="wifi-warp">
                    <div>
                      <img
                        v-if="currentPlayVideo != 'audio' + index"
                        :src="'https://cloudhospitalwebpro.knjs.net/static/VoiceMessage3.png'"
                        style="
                          height: 20px;
                          width: 20px;
                          margin-top: 7px;
                          margin-left: 7px;
                        "
                      />
                      <img
                        v-if="currentPlayVideo == 'audio' + index"
                        :src="'https://cloudhospitalwebpro.knjs.net/static/VoiceMessage4.png'"
                        style="
                          height: 15px;
                          width: 15px;
                          margin-top: 11px;
                          margin-left: 18px;
                        "
                      />
                    </div>
                    <div>
                      <span
                        style="
                          font-size: 14px;
                          line-height: 35px;
                          white-space: nowrap;
                          margin-right: 50px;
                        "
                      >
                        {{
                          JSON.parse(msgtext.msg).time == 0
                            ? 1
                            : JSON.parse(msgtext.msg).time
                        }}s
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img src="../assets/病人头像@3x.png" style="margin-left: 5px" />
        </div>
      </van-cell>
    </div>
    <!--    <div-->
    <!--        :style="{background: '#ffffff', position: 'fixed', bottom: '20px', width: '100%', paddingBottom: '5px', paddingTop: '5px', boxShadow: '0 0 5px 0 #DEDEDE'}">-->
    <!--      <img style="float: left;width:25px;height: 25px;margin-left: 10px;margin-top: 1px;"-->
    <!--           :src="'https://cloudhospitalwebpro.knjs.net/static/VoiceMessage.png'" :style="{display:Inputshow}"-->
    <!--           @click="changeInput(1)">-->
    <!--      <img style="float: left;width:25px;height: 25px;margin-left: 10px;margin-top: 1px;"-->
    <!--           :src="'https://cloudhospitalwebpro.knjs.net/static/VoiceMessage2.png'" @click='changeInput(2)' :style="{display:pshow}">-->
    <!--      <van-field v-model="sendMsg.msg" v-if="Inputshows" type="text" :style="{width: '200px' }" style="float: left;margin-left: 10px;font-size: 14px;" :height="52" border/>-->
    <!--      <div @touchstart='startRecord($event)' @touchend='endRecord($event)'-->
    <!--           :style="{display: pshow, marginLeft:marginLeft2, lineHeight: '25px',textAlign: 'center', width: '80%',margin: '0px auto'}">-->
    <!--        {{ speakvalue }}-->
    <!--      </div>-->
    <!--      <van-button type="primary" size="mini" :style="{display:Inputshow,position: 'absolute',right:'5px',bottom: '6px'}" @click="doSend()">发送</van-button>-->
    <!--    </div>-->
    <div
      style="
        background: #ffffff;
        position: fixed;
        bottom: 30px;
        width: 100%;
        height: 50px;
      "
    >
      <img
        style="
          float: left;
          width: 30px;
          height: 30px;
          margin-left: 10px;
          margin-top: 10px;
        "
        :src="'https://cloudhospitalwebpro.knjs.net/static/record.png'"
        :style="{ display: Inputshow }"
        @click="changeInput(1)"
      />
      <img
        style="
          float: left;
          width: 30px;
          height: 30px;
          margin-left: 10px;
          margin-top: 10px;
        "
        :src="'https://cloudhospitalwebpro.knjs.net/static/keyword.png'"
        @click="changeInput(2)"
        :style="{ display: pshow }"
      />
      <van-field
        class="communicationInput"
        v-if="Inputshows"
        v-model="sendMsg.msg"
        v-on:keyup.enter="doSend"
        maxlength="1000"
        style="
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          float: left;
          margin-top: 10px;
          height: 30px;
          padding-bottom: 0;
          padding-top: 0;
        "
        :style="{ width: KD }"
        type="发送"
        id="Chat"
      />
      <div
        @touchstart="startRecord($event)"
        @touchend="endRecord($event)"
        :style="{
          display: pshow,
          marginLeft: marginLeft2,
          lineHeight: '50px',
          textAlign: 'center',
          width: '80%',
          margin: '0px auto',
        }"
      >
        {{ speakvalue }}
      </div>
      <van-uploader v-if="Inputshows" :after-read="afterRead">
        <img
          style="float: left; width: 30px; height: 30px; margin-top: 10px"
          src="../assets/图片.png"
        />
      </van-uploader>
      <!--      <van-icon name="phone-o"-->
      <!--                size="35px"-->
      <!--                style="padding-left: -5px"-->
      <!--                v-on:click="ShowVideoChat"/>-->
      <van-button
        v-if="Inputshows"
        v-on:click="doSend"
        type="primary"
        style="
          float: right;
          margin-left: 10px;
          margin-top: 10px;
          height: 30px;
          margin-right: 10px;
        "
        >发送
      </van-button>
    </div>
  </div>
</template>
<script>
import PageTitle from '@/components/PageTitle.vue'
import {
  GetHisInformation
} from '@/api/consult.js'
import {
  GetRecipeStatus
} from '@/api/check.js'
import {
  getDefaultAccount,
  Pay,
  QueryAccount,
  SendSettlementMsg2User,
  OutpatientCharge,
  ElectronicInvoice,
  OrderOperation,
} from '@/api/user.js'
import wx from 'weixin-js-sdk'
import { GetJLXQ } from '@/api/check.js'
import {
  ImagePreview
} from 'vant'
// import { debounce } from 'lodash'
import Vue from 'vue'
// import {debounce} from '@/utils/debounce'
import { throttle } from '@/utils/throttle'
import {
  Loading
} from 'vant'
import VideoChat from '@/components/VideoChat.vue'
import moment from 'moment'

Vue.use(Loading)
// let mediaRecorder = null;
let div = document.getElementById('dhk')
const API_URL = process.env.VUE_APP_API_BASE_URL
export default {
  name: 'MyWebSocket',
  components: {
    PageTitle,
    VideoChat
  },
  data () {
    return {
      mediaSource: null,
      iconPrexName: 'icon',
      isDestroyed: false,
      jsq: 0,
      currentMsg: '',
      container: null,
      socket: null,
      receiveMsgText: [],
      marginLeft: '',
      sendMsg: {
        Id: '',
        senderId: '',
        senderName: '',
        targetId: '',
        msgType: 1,
        msg: '',
        key: '0',
      },
      KD: '',
      height: {
        height: '',
      },
      ckhm: '',
      width: {
        width: "",
      },
      i: 0,
      lock: true,
      locks: true,
      listData: [], //图片数组
      index2: 0,
      cardIDInfor: "",
      ApplyInfoID: '',
      display: {
        display: ""
      },
      unreadnum: '0',
      remainNum: '0',
      number: 0,
      heartbeatInstance: null,
      address1: '',
      address2: '',
      address3: '',
      address4: '',
      Image: '',
      zfwidth: '',
      openId: '',
      road: '',
      RecoordFirst: true,

      settlementLoading: false, //结算按钮加载状态

      pshow: 'none',
      pshows: 'none',
      Inputshow: 'block',
      Inputshows: true,
      speakvalue: '按住说话',
      tmp1: null,
      tmp2: null,
      voicelock: true,
      marginLeft2: '',
      marginLeft3: '',
      marginLeft4: '',
      mediaRecorder: null,
      screenTouch: {
        startX: 0,
        startY: 0,
        startHeight: 0,
        contentAnimation: {}
      },
      socketUrl: '',
      staticURL: '',
      audioDOM: null,
      currentPlayVideo: -1,
      tips: '',
      showTip: false,
      odertype: '',
    }
  },
  created: function () {
    // init sendMsg object
    // this.staticURL = process.env.VUE_STATIC_BASE_URL;
    // console.log(this.staticURL);
    this.GetID()

    this.address1 = localStorage.getItem('address1')
    this.address2 = localStorage.getItem('address2')
    this.address3 = localStorage.getItem('address3')
    this.address4 = localStorage.getItem('address4')
    this.address5 = localStorage.getItem('address5')
    if (localStorage.getItem('payOpenIds')) {
      this.openId = localStorage.getItem('payOpenIds')
      this.$store.dispatch('user/setOpenId', localStorage.getItem('payOpenIds'))
      if (localStorage.getItem('pdtj') == 1) {
        setTimeout(() => {
          this.settlementLoading = true
          getDefaultAccount(this.$store.getters.userInfo.UserId).then(
            (result) => {
              QueryAccount(3, result.ckhm, this.$store.getters.userInfo.UserId).then(
                (data) => {
                  let zhye = localStorage.getItem('zhye')
                  if ((data[0].zhye) * 1 > zhye * 1) {
                    OutpatientCharge(JSON.parse(localStorage.getItem('jsxx')))
                      .then(
                        (source) => {
                          let fphmL = []
                          source.forEach(function (item) {
                            fphmL.push(item.fphm)
                          })
                          ElectronicInvoice({
                            "fphm": fphmL,
                            "brid": source[0].brid,
                            "type": 2, //1挂号 2结算
                          }).then(
                            () => {
                            },
                            () => {
                            }
                          )
                          Vue.prototype.$toast('处方计费成功')
                          SendSettlementMsg2User({
                            "patientId": this.sendMsg.senderId,
                            "doctorId": this.sendMsg.targetId,
                            "msgId": [localStorage.getItem(
                              'consultid')],
                            "isSuccess": 1
                          }).then(
                            () => {
                              for (let j = 0; j < this
                                .receiveMsgText.length; j++) {
                                this.receiveMsgText[j].isshow =
                                  false
                              }
                            },
                            () => {
                            }
                          )
                          this.settlementLoading = false
                        },
                        (result) => {
                          Vue.prototype.$toast(result)
                          this.settlementLoading = false
                        }
                      )
                  } else {
                    Vue.prototype.$toast("结算失败，到账可能有延迟需手动结算")
                    this.settlementLoading = false
                  }
                },
                () => {
                  this.settlementLoading = false
                }
              )
            },
            () => {
              this.settlementLoading = false
            }
          )
        }, 3000)
      } else {
        setTimeout(() => {
          getDefaultAccount(this.$store.getters.userInfo.UserId).then(
            (result) => {
              QueryAccount(3, result.ckhm, this.$store.getters.userInfo.UserId).then(
                (data) => {
                  let zhye = localStorage.getItem('zhye')
                  if ((data[0].zhye) * 1 > zhye * 1) {
                    SendSettlementMsg2User({
                      "patientId": this.sendMsg.senderId,
                      "doctorId": this.sendMsg.targetId,
                      "msgId": [localStorage.getItem('consultid')],
                      "isSuccess": 1
                    }).then(
                      () => {
                        for (let j = 0; j < this.receiveMsgText
                          .length; j++) {
                          this.receiveMsgText[j].isshow = false
                        }
                      },
                      () => {
                      }
                    )
                  } else {
                    Vue.prototype.$toast("结算失败，到账可能有延迟需手动结算")
                  }
                },
                () => {
                }
              )
            },
            () => {
            }
          )
        }, 3000)
      }
    } else {
      this.openId = this.$store.getters.openId
    }
    if(this.$route.query.startDate) {
      this.showTip = true;
      let endDate = moment(this.$route.query.startDate).add(1, 'days').format('YYYY-MM-DD HH:mm:ss');
      let diffTime = moment(endDate).diff(moment(), 'second');
      if(diffTime < 60 * 60) {
        this.tips  = `您的咨询时间还剩${Math.floor(diffTime / 60)}分钟，请尽快结束咨询`
      } else {
        this.tips  = `您的咨询时间还剩${Math.floor(diffTime / 60 / 60)}小时，请尽快结束咨询`
      }
    }

    if (this.$route.params.targetId) {
      this.sendMsg.targetId = this.$route.params.targetId
    } else {
      this.sendMsg.targetId = localStorage.getItem('xxltargetId')
    }
    this.hh()
    if (this.$route.query.image) {
      this.Image = this.$route.query.image
    } else {
      this.Image = require('../assets/doctor@3x.png')
    }
    if (this.$route.params.doctor) {
      this.sendMsg.ApplyInfoId = this.$route.params.doctor
    } else {
      this.sendMsg.ApplyInfoId = localStorage.getItem('xxldoctor')
    }
    localStorage.setItem('pdtj', "")

    // 如果是图文咨询或者护理咨询就进行查询咨询状态
    /* if (option.ddlb && option.ddlb != '云门诊') {
      OrderOperation(this.ddbm, 'Query').then(res => {
        console.log(res)
        if (res.data.resultData.ddzt == '1') {
          this.endVisit = true
          this.startVisit = false
          this.consultStartTime = res.data.resultData.ddkssj.replace("T", " ")
          this.Timetips = this.getTimedata(this.consultStartTime, this.NowTime())
          this.Tips()
        } else if (res.data.resultData.ddzt == '0') {
          this.endVisit = false
          this.startVisit = false
        }
      })
    } */
  },
  mounted () {
    let script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = 'https://appx/web-view.min.js'
    document.body.appendChild(script)
    // let that = this;
    // // alert(`navigator.mediaDevices：${JSON.stringify(navigator.mediaDevices)}`);
    // navigator.mediaDevices.getUserMedia({audio: true}).then(stream=> {
    //   // alert(`stream:${JSON.stringify(stream)}`);
    //   this.mediaRecorder = new MediaRecorder(stream);
    //   // alert(`mediaRecorder：${JSON.stringify(this.mediaRecorder)}`)
    //   try {
    //     this.mediaRecorder.onStart(() => {
    //       that.tmp1 = new Date().getTime();
    //     });
    //   }catch (e) {
    //     console.log(this.mediaRecorder);
    //     // alert(`trymedia:${JSON.stringify(this.mediaRecorder)}`);
    //     // alert(`trycatch:${e}`)
    //   }
    // }).catch(err => {
    //   // alert(JSON.stringify(err));
    // });
    // this.mediaRecorder.onStop(function(res) {
    //   that.tmp2 = new Date().getTime();
    //   if (that.voicelock && (that.tmp2 - that.tmp1) > 1000) {
    //     let voicePath = res.tempFilePath;
    //     // eslint-disable-next-line no-undef
    //     my.uploadFile({
    //       url: `${API_URL}/api/User/UploadFiles`, //仅为示例，非真实的接口地址
    //       filePath: voicePath,
    //       name: 'fileList',
    //       // accept: 'text/plain',
    //       // // Content-Type::"multipart/form-data",
    //       // fileList:'@Desert.jpg;type=image/jpeg',
    //       success: (uploadFileRes) => {
    //         that.gettime = that.NowTime();
    //         that.sendMsg.msgType = 14;
    //         that.sendMsg.senderName = that.username;
    //         that.sendMsg.msg = JSON.stringify({
    //           "url": JSON.parse(uploadFileRes.data).resultData[0],
    //           "time": Math.ceil((that.tmp2 - that.tmp1) / 1000),
    //         });
    //         let _isSuccess__ = false;
    //         if (that.socketTask.readyState == 1) {
    //           _isSuccess__ = true;
    //           that.socketTask.send({
    //             data: JSON.stringify(that.sendMsg)
    //           });
    //         }
    //         that.receiveMsgText.push({
    //           senderName: that.username,
    //           msg: that.sendMsg.msg,
    //           msgtype: "3",
    //           sendid: that.sendMsg.senderId,
    //           sendDate: that.gettime,
    //           isSuccess: _isSuccess__,
    //         })
    //         that.sendMsg.msg = '';
    //
    //         that.$nextTick(function() {
    //           that.RecordScrollTop += 50
    //           that.scrollTop = that.RecordScrollTop;
    //         });
    //       }
    //     });
    //   } else if (that.tmp2 - that.tmp1 < 1000) {
    //     that.$refs.uToast.show({
    //       title: "说话时间太短",
    //     })
    //   }
    // });
  },
  destroyed: function () {
    //组件销毁时，停止心跳检测，以防反复进出聊天窗口导致死循环暴增 卡死服务器
    if (this.heartbeatInstance) {
      clearInterval(this.heartbeatInstance)
    }
    this.RecoordFirst = true
    //组件销毁时，释放socket实例，主动与服务器挥手断开连接。
    if (this.socket) {
      this.isDestroyed = true
      this.socket.close()
    }
  },
  methods: {
    //播放音频
    playAudio (item, index) {
      let _this = this
      let audio = _this.audioDOM
      if (audio != null) {
        if (_this.currentPlayVideo == `audio${index}`) {
          audio.pause()
          _this.currentPlayVideo = -1
          return
        }
        audio.pause()
      } else {
        audio = document.createElement('audio')
        _this.audioDOM = audio
      }
      audio.src = JSON.parse(item.msg).url
      audio.onload = function () { audio.play() }
      audio.play()
      this.currentPlayVideo = `audio${index}`
      audio.addEventListener('ended', function () {
        _this.currentPlayVideo = -1
        console.log(_this.currentPlayVideo)
        _this.$forceUpdate()
      }, false)
    },
    changeInput (e) {
      if (e == 1) {
        this.pshow = 'block'
        this.Inputshow = 'none'
        this.pshows = 'block'
        this.Inputshows = false
      } else {
        this.pshow = 'none'
        this.Inputshow = 'flex'
        this.pshows = 'none'
        this.Inputshows = true
      }
    },
    startRecord: throttle(function (event) {
      try {
        this.tmp1 = null
        this.tmp2 = null
        let touch = event.touches[0] || event.changedTouches[0]
        this.screenTouch.startX = touch.pageX
        this.screenTouch.startY = touch.pageY
        this.speakvalue = '放开发送'
        this.tmp1 = new Date().getTime()
        my.postMessage({ type: 'start', socketUrl: this.socketUrl, targetId: this.sendMsg.targetId, senderId: this.sendMsg.senderId, senderName: this.sendMsg.senderName })
        // navigator.mediaDevices.getUserMedia({audio: true}).then(stream=> {
        //   mediaRecorder = new MediaRecorder(stream);
        //   mediaRecorder.start();
        //   mediaRecorder.onstart = () =>{
        //     alert("record start")
        //   }
        // });
      } catch (e) {
        // alert(`startCatch:${e}`);
      }
    }, 1000),
    endRecord: throttle(function (event) {
      let touch = event.touches[0] || event.changedTouches[0]
      let horizontal = touch.pageX - this.screenTouch.startX
      let vertical = touch.pageY - this.screenTouch.startY
      this.voicelock = true
      if (Math.abs(horizontal) > Math.abs(vertical)) {
        //水平方向移动
      } else {
        //垂直方向移动
        if (vertical < -15) {
          //上
          this.voicelock = false
        } else {

          //
        }
      }
      console.log(this.voicelock)
      my.postMessage({
        type: 'end',
        voiceLock: this.voicelock,
      }
      )
      // this.mediaRecorder.stop();
      this.speakvalue = '按住说话'
    }, 1000),
    // ShowVideoChat() {
    //   let that = this;
    //   //向对方发送 视频请求 {type: 'request', isAccept: true}
    //   if (that.socket.readyState == WebSocket.OPEN) {
    //     that.socket.send(JSON.stringify({
    //       Id: '',
    //       senderId: that.sendMsg.senderId,
    //       senderName: that.sendMsg.senderName,
    //       targetId: that.sendMsg.targetId,
    //       msgType: 19, //视频请求=19
    //       msg: JSON.stringify({
    //         type: 'request',
    //         isAccept: true
    //       }),
    //     }));
    //   }
    // },
    OpenVideoChat () {
      let that = this
      //显示音频组件
      that.$refs.videochat.ShowOrHideVideo(true)
      //重写推流事件
      that.$refs.videochat.SendData2Server = function (mediaStream) {
        if (that.socket.readyState == WebSocket.OPEN) {
          try {
            that.$refs.videochat.mediaRecorder = new MediaRecorder(mediaStream, {
              mimeType: 'video/mp4;codecs="avc1.4d002a"', // 编码格式
              audioBitsPerSecond: 44100, // 44.1kHz
              videoBitsPerSecond: 3000000 // 3000k 画质
            })
            //进行媒体录制，把流变成二进制数据
            that.$refs.videochat.mediaRecorder.ondataavailable = (e) => {
              // 发送时 e.data 的类型是 Blob
              if (e.data && e.data.size > 0) {
                that.socket.send(e.data)
              }
            }
            // 开始录制并每隔 xx ms 发送一次数据
            that.$refs.videochat.mediaRecorder.start(500)
          } catch (e) {
            let types = [
              "video/mp4",
              'video/mp4;codecs="avc1.4d002a"',
              "video/webm",
              "video/webm;codecs=vp8",
              "video/webm;codecs=vp9",
              "video/webm;codecs=daala",
              "video/webm;codecs=h264",
              "video/webm;codecs=avc1",
              "video/x-matroska;codecs=avc1",
              "video/mpeg"
            ]
            let expMsg = ""
            for (let i in types) {
              expMsg += types[i] + (MediaRecorder.isTypeSupported(types[i]) ? "支持" : "否")
            }
            alert(`${expMsg} 。MediaRecorder异常: ${e}`)
          }
        } else {
          that.$refs.videochat.ShowOrHideVideo(false) //关闭音频组件
          alert("socket断开")
        }
      }
    },
    Accounts (jsxx, id) {
      // GetJLXQ()
      // console.log(localStorage.getItem('visitDetail'));
      const visitDetail = JSON.parse(localStorage.getItem('visitDetail'))
      const params = {
        ypxx1List: visitDetail.ypxx1List,
        totalFee: visitDetail.newtotalFee > 0 ? visitDetail.newtotalFee : visitDetail.totalFee,
        ksmc: visitDetail.ksmc,
        ygxm: visitDetail.ygxm,
        yysj: visitDetail.yysj,
        knxx: visitDetail.cardid,
        xm: visitDetail.xm,
        brid: visitDetail.patientid,
        doctorId: visitDetail.doctorId,
        hoscode: visitDetail.hoscode,
      }
      my.navigateTo({ url: `/pages/Settlement/Settlement?type=drug&param=${JSON.stringify(params)}` })
      // my.navigateTo({ url: '/pages/Settlement/Settlement' });
      /*let cfhmsz = new Array();
      for (let i = 0; i < jsxx.jsxx.length; i++) {
        cfhmsz.push(jsxx.jsxx[i].cfsb);
      }
      let that = this;
      that.settlementLoading = true;
      OutpatientCharge(jsxx).then(
        (source) => {
          let fphmL = [];
          source.forEach(function(item) {
            fphmL.push(item.fphm);
          })
          ElectronicInvoice({
            "fphm": fphmL,
            "brid": source[0].brid,
            "type": 2, //1挂号 2结算
          }).then(
            () => {},
            () => {}
          )
          that.settlementLoading = false;
          Vue.prototype.$toast('处方计费成功');
          SendSettlementMsg2User({
            "patientId": that.sendMsg.senderId,
            "doctorId": that.sendMsg.targetId,
            "msgId": [id],
            "isSuccess": 1
          }).then(
            () => {
              for (let j = 0; j < this.receiveMsgText.length; j++) {
                this.receiveMsgText[j].isshow = false
              }
            },
            () => {}
          );
        },
        (result) => {
          that.settlementLoading = false;
          if (result == "该处方已收费") {
            SendSettlementMsg2User({
              "patientId": that.sendMsg.senderId,
              "doctorId": that.sendMsg.targetId,
              "msgId": [id],
              "isSuccess": 1
            }).then(
              () => {
                for (let j = 0; j < this.receiveMsgText.length; j++) {
                  this.receiveMsgText[j].isshow = false
                }
              },
              () => {}
            );
          }
        }
      )*/
    },
    GetID () {
      getDefaultAccount(this.$store.getters.userInfo.UserId).then(
        (result) => {
          this.cardIDInfor = result
          this.sendMsg.senderId = "patient" + '-' + result.patientId
          //console.log(result);
          this.ckhm = result.ckhm
          //console.log(this.$route.params.doctor);
          this.sendMsg.senderName = this.cardIDInfor.brxm
          this.doConnect(this)
        },
        () => {
        }
      )
    },
    getImg (images) {
      let indexs = 0
      for (let i = 0; i < this.listData.length; i++) {
        if (this.listData[i] == images) {
          indexs = i
        }
      }
      ImagePreview({
        images: this.listData,
        showIndex: false,
        loop: true,
        startPosition: indexs
      })
    },
    afterRead (file) {
      let isSendSuccess = true
      this.sendMsg.msgType = 2
      var _this = this
      _this.gettime = this.NowTime()

      this.sendMsg.msg = JSON.stringify({
        'name': file.file.name,
        'content': file.content,
      })
      //如果连接未断开
      if (this.socket.readyState == WebSocket.OPEN) {
        this.socket.send(JSON.stringify(this.sendMsg))
      } else {
        //标记消息连接断开,需要重新发送
        isSendSuccess = false
      }

      this.listData.push(file.content)
      //图片压缩结束
      let imageIndex = this.listData.length - 1
      this.receiveMsgText.push({
        fileName: file.file.name,
        msg: file.content,
        msgtype: "2",
        senderName: this.sendMsg.senderName,
        sendid: this.sendMsg.senderId,
        sendDate: _this.gettime,
        imageIndex: imageIndex,
        ApplyInfoId: this.sendMsg.ApplyInfoId,
        isSendSuccess: isSendSuccess,
      })
      this.sendMsg.msg = ''
      clearTimeout(this.timeOut)
      this.timeOut = setTimeout(() => {
        if (div.scrollHeight) {
          div.scrollTop = div.scrollHeight
        }
      }, 100)
    },
    hh () {
      this.width.width = document.documentElement.clientWidth - 120 + 'px'
      this.KD = document.documentElement.clientWidth - 155 + 'px'
      this.zfwidth = document.documentElement.clientWidth - 100 + 'px'
      this.marginLeft = (document.documentElement.clientWidth - 100 - 112 - 20) / 2 + 'px'
    },
    GetHis (e) {
      let app = this
      GetHisInformation(app.receiveMsgText[0].sendDate, app.sendMsg.senderId, app.sendMsg.targetId, e).then(
        (result) => {
          if (result.data.resultStatus == "200" && result.data.msg.indexOf('没有更多了...') == -1) {
            let sz = result.data.resultData.details
            app.unreadnum = result.data.resultData.unReadNum
            app.remainNum = result.data.resultData.remainNum
            for (let i = sz.length - 1; i >= 0; i--) {
              if (sz[i].msgType != '17') {
                if (sz[i].msgType == "2") {
                  app.listData.unshift(sz[i].msg)
                  sz[i].imageIndex = app.listData.length - 1
                }
                app.receiveMsgText.unshift({
                  msg: sz[i].msg,
                  msgtype: sz[i].msgType,
                  sendid: sz[i].senderId,
                  sendDate: sz[i].sendDate,
                  imageIndex: sz[i].imageIndex,
                  senderName: sz[i].senderName,
                  ApplyInfoId: sz[i].ApplyInfoId,
                  isSendSuccess: true,
                  id: sz[i].id,
                  isshow: false,
                })
              }
            }
            if (app.unreadnum != '0' || app.showTip) {
              app.height.height = document.documentElement.clientHeight - 150 + 'px'
            } else {
              app.height.height = document.documentElement.clientHeight - 100 + 'px'
            }
            //                            InforMationRead(TJ);
            div.scrollTop = 0
          }
        },
        () => {
        }
      )
      app.number++
    },
    heartbeat () {
      var self = this
      self.socket.send(JSON.stringify({
        msg: '',
        msgtype: "6",
        senderName: self.sendMsg.senderName,
        senderid: self.sendMsg.senderId,
        targetId: self.sendMsg.targetId,
        id: ''
      }))
    },
    pay (mzhm, ckhm, tsy, jzxh, id, jsxx) {
      my.navigateTo({ url: '/pages/Settlement/Settlement?billId=' })
      /*localStorage.setItem('jsxx', JSON.stringify(jsxx));
      let pay = {
        "openId": this.openId,
        "ip": "172.0.0.1",
        "patientName": this.sendMsg.senderName,
        "mzhm": mzhm,
        "ckhm": ckhm,
        "attach": "string",
        "fee": tsy.substring(tsy.lastIndexOf(":") + 1, tsy.length),
      }
      QueryAccount(3, this.ckhm, this.$store.getters.userInfo.UserId).then(
        (data) => {
          localStorage.setItem('zhye', (data[0].zhye) * 1);
        },
        () => {}
      );
      if (jzxh) {
        let cfhmsz = new Array();
        for (let i = 0; i < jsxx.jsxx.length; i++) {
          cfhmsz.push(jsxx.jsxx[i].cfsb);
        }
        let judge = false;
        let tsy = "";
        GetRecipeStatus("[" + cfhmsz + "]").then(
          (result) => {
            for (let i = 0; i < result.length; i++) {
              if (!result[i].fphm && result[i].zfpb == 0) {
                judge = true;
              } else {
                judge = false;
                tsy += "已计费";
                break;
              }
            }
            if (result.length != cfhmsz.length) {
              judge = false;
              tsy = "已删除"
            }
            if (judge) {
              Pay(pay).then(
                (result) => {
                  localStorage.setItem('xxltargetId', this.sendMsg.targetId);
                  localStorage.setItem('xxlimage', this.$route.query.image);
                  localStorage.setItem('xxldoctor', this.sendMsg.ApplyInfoId);
                  localStorage.setItem('payOpenIds', this.$store.getters.openId);
                  localStorage.setItem('consultid', id);
                  localStorage.setItem('pdtj', "1");
                  wx.miniProgram.postMessage({
                    data: 'res_id值'
                  })
                  wx.miniProgram.redirectTo({
                    url: '/pages/webview/webview?res=' + result
                  })
                  //                        wx.miniProgram.navigateTo({url: '/pages/webviewresult/webviewresult?res=' + result})
                },
                () => {}
              );
            } else {
              Vue.prototype.$toast("该条处方" + tsy);
            }
          },
          () => {}
        )

      } else {
        Pay(pay).then(
          (result) => {
            localStorage.setItem('xxltargetId', this.sendMsg.targetId);
            localStorage.setItem('xxlimage', this.$route.query.image);
            localStorage.setItem('xxldoctor', this.sendMsg.ApplyInfoId);
            localStorage.setItem('payOpenIds', this.$store.getters.openId);
            localStorage.setItem('consultid', id);
            localStorage.setItem('pdtj', "2");
            wx.miniProgram.postMessage({
              data: 'res_id值'
            })
            wx.miniProgram.redirectTo({
              url: '/pages/webview/webview?res=' + result
            })
            //                        wx.miniProgram.navigateTo({url: '/pages/webviewresult/webviewresult?res=' + result})
          },
          () => {}
        );
      }*/
    },
    doConnect (app) {
      let uri = process.env.VUE_APP_API_WSS_URL + '/ws?senderId=' + this.sendMsg.senderId + '&targetId=' + this
        .sendMsg.targetId
      this.socketUrl = uri
      let that = this
      this.socket = new WebSocket(uri)
      //定义定时器 做心跳检测
      that.heartbeatInstance = setInterval(() => {
        //如果连接状态是开启的
        if (that.socket && that.socket.readyState == WebSocket.OPEN) {
          that.heartbeat()
        } else {
          //清除原先的定时器
          if (that.heartbeatInstance) {
            clearInterval(that.heartbeatInstance)
          }
        }
      }, 30000, that)
      this.socket.onopen = function () {
        let _this = this
        _this.gettime = app.NowTime()
        if (app.locks == true && app.RecoordFirst) {
          app.display.display = 'block'
          GetHisInformation(_this.gettime, app.sendMsg.senderId, app.sendMsg.targetId, 15).then(
            (result) => {
              if (result.data.resultStatus == "200" && result.data.msg.indexOf('没有更多了...') == -
                1) {
                app.receiveMsgText = []
                app.listData = []
                let sz = result.data.resultData.details
                for (let i = 0; i < sz.length; i++) {
                  if (sz[i].msgType == "2") {

                    app.listData.push(sz[i].msg)
                    sz[i].imageIndex = app.listData.length - 1
                  } else if (sz[i].msgType == "4") {
                    sz[i].isshow = true
                    for (let j = 0; j < app.receiveMsgText.length; j++) {
                      app.receiveMsgText[j].isshow = false
                    }
                  }
                  if (sz[i].msgType != '17') {
                    app.receiveMsgText.push({
                      msg: sz[i].msg,
                      msgtype: sz[i].msgType,
                      sendid: sz[i].senderId,
                      sendDate: sz[i].sendDate,
                      imageIndex: sz[i].imageIndex,
                      senderName: sz[i].senderName,
                      ApplyInfoId: app.sendMsg.ApplyInfoId,
                      isSendSuccess: true,
                      id: sz[i].id,
                      isshow: sz[i].isshow,
                    })
                  }
                }
                app.unreadnum = result.data.resultData.unReadNum
                app.remainNum = result.data.resultData.remainNum
                if (app.unreadnum != '0' || app.showTip) {
                  app.height.height = document.documentElement.clientHeight - 165 + 'px'
                } else {
                  app.height.height = document.documentElement.clientHeight - 115 + 'px'
                }
              }
              app.display.display = 'none'

            },
            () => {
            }
          )
          app.RecoordFirst = false
          app.locks = false
          clearTimeout(app.timeOut)
          app.timeOut = setTimeout(() => {
            div = document.getElementById('dhk')
            div.scrollTop = div.scrollHeight || '0'
          }, 1000)
          setTimeout(() => {
            div = document.getElementById('dhk')
            if (div) {
              div.scrollTop = div.scrollHeight || '0'
            }

            document.getElementById("dhk").addEventListener("scroll", function () {
              if (div.scrollTop == "0" && app.lock == true) {
                GetHisInformation(app.receiveMsgText[0].sendDate, app.sendMsg
                  .senderId, app.sendMsg.targetId, 20).then(
                    (result) => {
                      let TJ = []
                      if (result.data.resultStatus == "200" && result.data
                        .msg.indexOf('没有更多了...') == -1) {
                        app.display.display == 'block'
                        let sz = result.data.resultData.details
                        app.unreadnum = result.data.resultData.unReadNum
                        app.remainNum = result.data.resultData.remainNum
                        for (let i = sz.length - 1; i >= 0; i--) {
                          if (sz[i].msgType != '17') {
                            if (sz[i].msgType == "2") {
                              app.listData.unshift(sz[i].msg)
                              sz[i].imageIndex = app.listData
                                .length - 1
                            }
                            app.receiveMsgText.unshift({
                              msg: sz[i].msg,
                              msgtype: sz[i].msgType,
                              sendid: sz[i].senderId,
                              sendDate: sz[i].sendDate,
                              imageIndex: sz[i].imageIndex,
                              senderName: sz[i].senderName,
                              ApplyInfoId: sz[i].ApplyInfoId,
                              isSendSuccess: true,
                              id: sz[i].id,
                              isshow: sz[i].isshow,
                            })
                            TJ.push(sz[i].id)
                          }
                        }
                        app.display.display == 'none'
                      } else {
                        app.lock = 'false'
                      }
                    },
                    () => {
                    }
                  )
              }
            })
          }, 2000)
        }
      }
      this.socket.onclose = function () {
        //如果是路由跳转出去，就不重连了
        if (!that.isDestroyed) {
          //断线后重连,为了防止太丧心病狂的重连,这里做延时
          setTimeout(() => {
            that.doConnect(that)
          }, 5000, that)
        }
      }
      this.socket.onmessage = function (e) {
        let rmsg = JSON.parse(e.data)
        if (rmsg['MsgType'] != "6" && rmsg['MsgType'] != "17") {
          if (rmsg['MsgType'] == "19") {
            /*result格式
              {
                type: 'response' , //request
                isAccept: true,
              }
            */
            //request=对方发起请求此处为应答，response=对方的应答结果
            let result = JSON.parse(rmsg['Msg'])
            if (result['type'] == 'response' && result['isAccept']) {
              that.OpenVideoChat() //对方已确认，展示音频组件
            } else if (result['type'] == 'request') {
              that.mediaSource = new MediaSource()
              that.$refs.videochat.videoInstance.src = URL.createObjectURL(that.mediaSource)
              that.mediaSource.addEventListener('sourceopen', function () {
                // var data = new Uint8Array([0x1F, 0x9A, 0x3B]);
                // var mimeType = 'video/mp4;codecs="avc1.4d002a"';
                // let sourceBuffer = that.mediaSource.addSourceBuffer(mimeType);
                // sourceBuffer.appendBuffer(data);
              })
            } else {
              alert("对方拒绝!")
            }
          } else {
            if (rmsg['MsgType'] == "2") {
              app.listData.push(rmsg['Msg'])
            }
            if (rmsg['MsgType'] != "4") {
              app.receiveMsgText.push({
                senderName: rmsg['SenderName'],
                msg: rmsg['Msg'],
                msgtype: rmsg['MsgType'],
                sendid: rmsg['SenderId'],
                sendDate: rmsg["SendDate"],
                isSendSuccess: true,
                id: rmsg['id'],
              })
            } else {
              for (let j = 0; j < app.receiveMsgText.length; j++) {
                app.receiveMsgText[j].isshow = false
              }
              app.receiveMsgText.push({
                senderName: rmsg['SenderName'],
                msg: rmsg['Msg'],
                msgtype: rmsg['MsgType'],
                sendid: rmsg['SenderId'],
                sendDate: rmsg["SendDate"],
                isSendSuccess: true,
                isshow: true
              })
            }
            clearTimeout(this.timeOut)
            this.timeOut = setTimeout(() => {
              if (div.scrollHeight) {
                div.scrollTop = div.scrollHeight
              }
            }, 100, div)
          }
        }
      }
      this.socket.onerror = function (e) {
        console.log("触发onerror!" + e)
      }
    },
    NowTime () {
      let yy = new Date().getFullYear()
      let mm =
        new Date().getMonth() + 1 < 10 ?
          "0" + (new Date().getMonth() + 1) :
          new Date().getMonth() + 1
      let dd =
        new Date().getDate() < 10 ?
          "0" + new Date().getDate() :
          new Date().getDate()
      let hh = new Date().getHours() < 10 ?
        "0" + new Date().getHours() :
        new Date().getHours()
      let mf =
        new Date().getMinutes() < 10 ?
          "0" + new Date().getMinutes() :
          new Date().getMinutes()
      let ss =
        new Date().getSeconds() < 10 ?
          "0" + new Date().getSeconds() :
          new Date().getSeconds()
      let gettime = yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss
      return gettime
    },
    //消息重发
    ReSend (msgText, index) {
      if (this.socket.readyState == WebSocket.OPEN) {
        if (this.receiveMsgText[index]) {
          this.receiveMsgText[index]["isSendSuccess"] = true
        }
        //如果是图片 要传结构化数据
        if (msgText.msgType == 2) {
          msgText.msg = JSON.stringify({
            'name': msgText.fileName,
            'content': msgText.msg,
          })
        }
        let _sendMsg = JSON.stringify({
          senderId: this.sendMsg.senderId,
          senderName: this.sendMsg.senderName,
          targetId: this.sendMsg.targetId,
          msgType: msgText.msgtype,
          msg: msgText.msg,
          ApplyInfoId: msgText.ApplyInfoId,
        })
        this.socket.send(_sendMsg)
      }
    },
    doSend () {
      if (this.sendMsg.msg != "" && this.sendMsg.msg.split(" ").join("").length != 0) {
        let _this = this
        let isSendSuccess = true
        this.sendMsg.msgType = 1
        _this.gettime = this.NowTime()
        //如果连接未断开
        if (this.socket.readyState == WebSocket.OPEN) {
          this.socket.send(JSON.stringify(this.sendMsg))
        } else {
          //标记消息连接断开,需要重新发送
          isSendSuccess = false
        }
        this.receiveMsgText.push({
          senderName: this.sendMsg.senderName,
          msg: this.sendMsg.msg,
          msgtype: "1",
          sendid: this.sendMsg.senderId,
          sendDate: _this.gettime,
          ApplyInfoId: this.sendMsg.ApplyInfoId,
          isSendSuccess: isSendSuccess, //消息发送状态
        })
        this.sendMsg.msg = ''

        setTimeout(() => {
          if (div.scrollHeight) {
            div.scrollTop = div.scrollHeight
          }
        }, 100)
      }
      var chat = document.getElementById("Chat")
      chat.focus()
    },
    //时间判断
    PD (index) {
      //不为第一条，且为今天,时间超过10分钟
      //                if(index!=0 && this.receiveMsgText[index].sendDate.substring(0,10)==_this.gettime &&(this.receiveMsgText[index].sendDate.substring(11,13)*60)*1+(this.receiveMsgText[index].sendDate.substring(14,16))*1-(this.receiveMsgText[index-1].sendDate.substring(11,13)*60)*1-(this.receiveMsgText[index-1].sendDate.substring(14,16))*1>=10){
      //                    return this.receiveMsgText[index].sendDate.substring(11,16);
      //                }
      if (index != "0") {
        var _this = this
        let yy = new Date().getFullYear()
        var mm =
          new Date().getMonth() + 1 < 10 ?
            "0" + (new Date().getMonth() + 1) :
            new Date().getMonth() + 1
        var dd =
          new Date().getDate() < 10 ?
            "0" + new Date().getDate() :
            new Date().getDate()
        _this.gettime = yy + "-" + mm + "-" + dd

        let dat = this.receiveMsgText[index].sendDate.substring(0, 10) + " " + this.receiveMsgText[index]
          .sendDate.substring(11, 19)
        let myDate = this.receiveMsgText[index - 1].sendDate.substring(0, 10) + " " + this.receiveMsgText[
          index - 1].sendDate.substring(11, 19)
        if (this.getTimedata(dat, myDate) >= 600 && this.receiveMsgText[index].sendDate.substring(0, 10) ==
          _this.gettime) {
          return this.receiveMsgText[index].sendDate.substring(11, 16)
        } else if (this.getTimedata(dat, myDate) >= 600 && this.receiveMsgText[index].sendDate.substring(0,
          10) != _this.gettime) {
          return this.receiveMsgText[index].sendDate.substring(0, 10) + " " + this.receiveMsgText[index]
            .sendDate.substring(11, 16)
        }
      } else {
        return ""
      }
    },
    getTimedata (myDate, dat) {
      let getYeardata = dat.split(" ")[0]
      let getTimedata = dat.split(" ")[1]
      //  年月日  时分秒
      let beforeYear = getYeardata.split("-")[0]
      let beforeMonth = getYeardata.split("-")[1]
      let beforeDate = getYeardata.split("-")[2]
      let beforeHours = getTimedata.split(":")[0]
      let beforeMinutes = getTimedata.split(":")[1]
      let beforeSeconds = getTimedata.split(":")[2]
      let getYeardatas = myDate.split(" ")[0]
      let getTimedatas = myDate.split(" ")[1]
      let afterYear = getYeardatas.split("-")[0]
      let afterMonth = getYeardatas.split("-")[1]
      let afterDate = getYeardatas.split("-")[2]
      let afterHours = getTimedatas.split(":")[0]
      let afterMinutes = getTimedatas.split(":")[1]
      let afterSeconds = getTimedatas.split(":")[2]

      // 计算差值
      let getDifference = (afterYear - beforeYear) * 365 * 24 * 60 * 60
      getDifference += (afterMonth - beforeMonth) * 30 * 24 * 60 * 60
      getDifference += (afterDate - beforeDate) * 24 * 60 * 60
      getDifference += (afterHours - beforeHours) * 60 * 60
      getDifference += (afterMinutes - beforeMinutes) * 60
      getDifference += (afterSeconds - beforeSeconds)
      return getDifference
    }
  }
}
</script>
<style lang="less">
.communicationInput input {
  border: 2px solid #1678fd;
  padding: 0 5px;
}
</style>
<style scoped lang="less">
@import "../style/var.less";

.van-cell::after {
  border-bottom: 0;
}

.doctorCommunicationInputs {
  background-color: @white;
  color: @green;
  padding: 5px 10px;
  border-radius: 8px;
  box-shadow: 0px 0px 5px 0px @gray-5;
  margin: 5px;
  max-width: 65vw;
}

.customerCommunicationInputs {
  background-color: @green;
  color: @white;
  padding: 5px 10px;
  border-radius: 8px;
  margin: 0 5px;
  max-width: 65vw;
}

.child {
  width: 120px;
  height: 20px;
  background: white;
  /*border-radius: 4px;*/
  color: #000;
  font-size: 14px;
  border: 1px solid #1678fd;
  border-right: none;
  border-radius: 16px 0 0 16px;
  /*p {
  border:1px solid #1678FD;
}*/
}

/*** 右侧聊天框 start***/
.chat-right {
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

.chat-right > img {
  margin-top: 5px;
  max-width: 44px;
  max-height: 44px;
}

.chat-right > div > div {
  display: flex;
  justify-content: flex-end;
}

.chat-right > div > label {
  color: #000;
  font-size: 12px;
  margin: 0 5px;
}

/** 重连图标 Start **/
.resend-icon:hover {
  cursor: pointer;
}

.list-left {
  text-align: left;
  background: #ffffff;
  box-shadow: 0 0 5px 0 #dedede;
  border-radius: 8px;

  margin-left: 30px;
  color: #000;
  font-size: 12px;
  padding-left: 5px;
}

.wifi-warp {
  background-color: #1989fa;
  border-radius: 8px;
  color: #ffffff;
  height: 35px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

/** 重连图标 End **/

/* 右侧聊天框 End */
</style>
