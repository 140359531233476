import Axios from 'axios';

let axios = Axios.create({
    // baseURL: process.env.NODE_ENV === 'development' ? '/development' : 'http://cloudhospital.knjs.com.cn', // url = base url + request url
    baseURL: process.env.VUE_APP_API_BASE_URL, // url = base url + request url
    withCredentials: false, // send cookies when cross-domain requests
    timeout: 5000 // request timeout
});

export function GetHisInformation (lastSendDate, senderID, targetId, num) {
    return axios.get('/api/User/GetUserHisChatMsgBySendDateDecreasing?lastSendDate=' + lastSendDate + '&senderID=' + senderID + '&targetId=' + targetId + '&num=' + num);
}

export function GetJZLB (CardID, index) {
    return axios.get('/api/DrugLogistic/GetRecordList?CardID=' + CardID + '&index=' + index + '&size=10');
}








