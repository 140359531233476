<template>
  <div>
    <div id="mainVideo"
         class="main-video">
      <div class="button-group">
        <button class="common-button"
                type="primary"
                v-on:click="StartLive">开启</button>
        <button class="common-button"
                type="primary"
                v-on:click="ShowOrHideVideo(false)">结束</button>
        <button class="common-button"
                type="primary"
                v-on:click="Mute">静音</button>
        <button class="common-button"
                type="primary"
                v-on:click="FrontChange">转换</button>
      </div>
      <video :width=clientWidth
             :height=clientHeight
             id="myVideo"
             class="my-video"
             controls="controls"
             controlslist="nodownload nofullscreen noremoteplayback"
             playsinline="true"
             x5-playsinline="true"
             webkit-playsinline="true"
             x-webkit-airplay="allow"
             x5-video-player-type="h5"
             x5-video-player-fullscreen="true"
             x5-video-orientation="portraint"
             disablePictureInPicture></video>
    </div>
  </div>
</template>
<script>

export default {
  components: {
  },
  data () {
    return {
      isFront: true,
      isStop: false,
      mediaRecorder: null,
      videoInstance: document.getElementById("myVideo"),
      mainVideoInstance: document.getElementById("mainVideo"),
      clientWidth: document.documentElement.clientWidth,
      clientHeight: document.documentElement.clientHeight,
    }
  },
  created: function () {

  },
  mounted: function () {
    let that = this;
    that.videoInstance = document.getElementById('myVideo');
    that.mainVideoInstance = document.getElementById('mainVideo');
  },
  methods: {
    StartLive () {
      let that = this;
      if (that.videoInstance) {
        navigator.mediaDevices.getUserMedia({
          audio: true,
          video: {
            width: that.clientWidth,
            height: that.clientHeight,
            facingMode: (that.isFront ? "user" : "environment"), //摄像头 前置or后置
            frameRate: { //帧率
              ideal: 10,
              max: 15
            },
          }
        }).then(function (mediaStream) {
          if (that.videoInstance) {
            that.videoInstance.srcObject = mediaStream;
            that.SendData2Server(mediaStream);
            //ios全屏事件
            that.videoInstance.addEventListener('webkitpresentationmodechanged', () => {
              that.videoInstance.play();
            })

            //重写暂停事件
            that.videoInstance.addEventListener("pause", function () {
              if (that.isStop) {
                that.videoInstance.play();
              }
            });

            //微信内置的事件
            document.addEventListener('WeixinJSBridgeReady', function () {
              that.videoInstance.muted = true;
              that.videoInstance.play();
            }, false, that)

            that.videoInstance.onloadedmetadata = function () {
              that.videoInstance.muted = true;
              that.videoInstance.play();
            }
          } else {
            alert(`报错!${that.videoInstance}`);
          }
        }).catch(function (err) {
          alert(`报错!${err}`);
        })
      }
    },
    Mute () {
      if (this.videoInstance) {
        this.videoInstance.muted = !this.videoInstance.muted;
      }
    },
    FrontChange () {
      if (this.videoInstance) {
        this.isFront = !this.isFront;
        this.videoInstance.facingMode = this.isFront ? "user" : "environment";
        this.StartLive();
      }
    },
    ShowOrHideVideo (isShow) {
      if (isShow) {
        this.mainVideoInstance.style = "visibility:visible;";
        this.StartLive();
      }
      else {
        this.mainVideoInstance.style = "visibility:hidden;";
        this.isStop = true;
        if (this.mediaRecorder) {
          this.mediaRecorder.stop();
        }
        this.videoInstance.pause();
      }
    },
    SendData2Server (mediaStream) {
      console.log(mediaStream);
    }
  }
}
</script>
<style>
/* 全屏 */
video::-webkit-media-controls-fullscreen-button {
  display: none !important;
}
/* 播放按钮 */
video::-webkit-media-controls-play-button {
  display: none !important;
}
/* 进度条 */
video::-webkit-media-controls-timeline {
  display: none !important;
}
/* 观看的当前时间 */
video::-webkit-media-controls-current-time-display {
  display: none !important;
}
/* 剩余时间 */
video::-webkit-media-controls-time-remaining-display {
  display: none !important;
}

.main-video {
  background-color: rgb(51, 51, 51);
  margin-top: 46;
  position: absolute;
  z-index: 1000;
}
.my-video {
  background-color: rgb(51, 51, 51);
  margin-top: 0px;
  z-index: 1000;
}
.button-group {
  margin-top: 40px;
  position: absolute;
  background-color: rgb(51, 51, 51);
  z-index: 1001;
}
.common-button {
  color: rgb(175, 174, 174);
  border: none;
  background-color: rgb(51, 51, 51);
  margin: 15px 0px 15px 5px;
  width: auto;
  display: block;
  z-index: 1000;
}
</style>
